import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import { MDXRenderer } from "gatsby-plugin-mdx"
import styles from './powerup-intro.module.scss'

export default function Page({ data }) {
	const post = data.mdx
	return (
		<Layout>
			<SEO title={post.frontmatter.title} description={post.excerpt} />
			<div>
				<div className={styles.header}>
					<img className={styles.image} src={post.frontmatter.iconLink} />
					<h1 className={styles.title}>{post.frontmatter.title}</h1>
					<a href={`https://trello.com/power-ups/${post.frontmatter.idPowerUp}/enable`}>
						<img alt="Add to Trello" height="40" width="144" src="https://p.trellocdn.com/add_to_trello.png" srcSet="https://p.trellocdn.com/add_to_trello.png 1x, https://p.trellocdn.com/add_to_trello@2x.png 2x" />
					</a>
				</div>
				<MDXRenderer>{post.body}</MDXRenderer>
			</div>
		</Layout>
	)
}

export const query = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      frontmatter {
        title
				idPowerUp
				iconLink
      }
      excerpt
    }
  }
`
